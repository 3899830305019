/*
=============== 
Global Styles
===============
*/

*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  background: rgb(242, 233, 234);
  line-height: 1.5;
  font-size: 0.875rem;
}

ul {
  list-style-type: none;
}
a {
  text-decoration: none;
}
h1,
h2,
h3,
h4 {
  letter-spacing: var(--spacing);
  text-transform: capitalize;
  line-height: 1.25;
  margin-bottom: 0.75rem;
}
h1 {
  font-size: 3rem;
}
h2 {
  font-size: 1.5rem;
}
h3 {
  font-size: 1rem;
}
h4 {
  font-size: 0.875rem;
}
p {
  margin-bottom: 1.25rem;
  color: var(--clr-grey-5);
}
@media screen and (min-width: 800px) {
  h1 {
    font-size: 4rem;
  }
  h2 {
    font-size: 2.5rem;
  }
  h3 {
    font-size: 1.25rem;
  }
  h4 {
    font-size: 1rem;
  }
  body {
    font-size: 1rem;
  }
  h1,
  h2,
  h3,
  h4 {
    line-height: 1;
  }
}

main {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-content: center;
}

section {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 50%;
  margin-top: 5rem;
  margin-bottom: 5rem;
  justify-content: center;
  align-items: center;
}

@media (max-width: 1000px) {
  section {
    width: 85%;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
}

.title {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 2rem;
  margin-top: 2rem;
}

.bitcoin {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 3rem;
  padding: 2rem;
  border-radius: 1.5rem;
  background: white;
  --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.img {
  height: 5rem;
  width: 5rem;
  object-fit: cover;
  border-radius: 9999px;
  margin-bottom: 2rem;
  --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.btcGif {
  height: 2rem;
  width: 2rem;
  object-fit: cover;
  border-radius: 9999px;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.ipoGif {
  height: 2rem;
  width: 2rem;
  object-fit: cover;
  border-radius: 9999px;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.desc {
  color: gray;
  text-align: center;
  margin-bottom: 2rem;
}

.btn {
  background: blue;
  color: white;
  padding: 1rem;
  border-radius: 0.75rem;
  outline: none;
  border: none;
  cursor: pointer;
}

.btn:hover {
  background: orange;
}

.calcContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  /* flex-grow: 1; */
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 2rem;
  text-align: center;
  padding-left: 2rem;
  padding-right: 2rem;
  /* word-break: break-word;
  overflow-wrap: break-word; */
  /* min-width: 10%; */
}

@media (max-width: 1000px) {
  .calcContainer {
    padding-left: 0rem;
    padding-right: 0rem;
  }
}

.span {
  display: flex;
  flex-direction: row;
  /* justify-content: center;
  align-items: center; */
  flex-wrap: wrap;
  margin: 0;
  /* 
  min-width: 30%; */
}

.btc {
  display: flex;
  flex-direction: row;
  /* justify-content: center;
  align-items: center; */
  /* min-width: 40%; */
  /* flex-wrap: wrap; */
  margin: 0;
  color: orange;
}

.company {
  display: flex;
  flex-direction: row;
  /* justify-content: center;
  align-items: center; */
  margin: 0;
  color: red;
}

.oppCost {
  display: flex;
  flex-direction: row;
  /* justify-content: center;
  align-items: center; */

  margin: 0;
  color: rgb(18, 211, 18);
}

.oppTime {
  display: flex;
  flex-direction: row;
  /* justify-content: center;
  align-items: center; */
  margin: 0;
  color: rgb(38, 79, 245);
}

.investmentContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding-bottom: 1rem;
  padding-top: 1rem;
  width: 100%;
  /* margin-left: 1.5rem; */
}

.initialInvestment {
  display: flex;
  flex-direction: row;
  margin-bottom: 0;
  margin-right: 0.25rem;
  /* width: 50%; */
  /* margin-left: 3rem;  */
}

.investmentAmountWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* width: 50%; */
  /* width: 5rem; */
}

.investmentAmount {
  display: block;
  /* width: inherit; */
  font-size: inherit;
  /* font-size: 0.875rem; */
  outline: none;
  border: none;
  color: inherit;
  background: inherit;
  text-align: start;
  /* width: 5rem; */
}

/* .investmentAmount::placeholder {
  font-weight: 600;
} */

.dollar {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 0rem;
  margin-right: 0.15rem;
  font-weight: bold;
}

input {
  letter-spacing: var(--spacing);
  text-transform: capitalize;
  /* font-size: 0.875; */
  font-weight: bold;
}
/* 
input::placeholder {
  font-weight: 500;
} */

.incomeContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding-bottom: 3rem;
  padding-top: 1rem;
  width: 100%;
  /* margin-left: 1.5rem; */
}

.income {
  display: flex;
  flex-direction: row;
  margin-bottom: 0;
  margin-right: 0.25rem;
  /* width: 50%; */
  /* margin-left: 3rem;  */
}

.incomeAmountWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* width: 50%; */
  /* width: 5rem; */
}

.incomeAmount {
  display: block;
  /* width: inherit; */
  font-size: inherit;
  /* font-size: 0.875rem; */
  outline: none;
  border: none;
  color: inherit;
  background: inherit;
  text-align: start;
  /* width: 5rem; */
}

.imgContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-left: 2rem;
  padding-right: 2rem;
}

.vs {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.ipoDate {
  margin-bottom: 0.5rem;
  font-weight: 400;
  text-align: center;
}

.btcPriceIpoDate {
  margin-bottom: 1.5rem;
  font-weight: 400;
  text-align: center;
}

.btnContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 1.5rem;
}

.previousIcon {
  /* margin-right: 0.5rem; */
  justify-content: center;
  align-items: center;
  background: none;
  color: rgb(119, 162, 242);
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 1.5rem;
}

.nextIcon {
  margin-left: 2rem;
  justify-content: center;
  align-items: center;
  background: none;
  color: rgb(119, 162, 242);
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 1.5rem;
}

.xyPlot {
  margin-top: 0.5rem;
  margin-bottom: 2rem;
}

.tick {
  height: 3rem;
  width: 3rem;
  margin-left: -1.75rem;
  margin-top: -5rem;
}

.cross {
  height: 2rem;
  width: 2rem;
  margin-left: -0.75rem;
  margin-top: -5rem;
}

.idiotBtn {
  background: white;
  color: black;
  padding: 1rem;
  border-radius: 0.75rem;
  outline: none;
  border: 1px solid blue;
  cursor: pointer;
}

.idiotBtn:hover {
  background: red;
  color: white;
  border: 1px solid white;
}

.donate {
  text-decoration: underline;
  color: blue;
}

.currentOwner {
  text-decoration: underline;
  color: blue;
}

.share {
  display: flex;
  flex-direction: row;
  margin-left: 1rem;
}

.loveEmoji {
  color: red;
}
